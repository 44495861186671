import { createActions, ErrorActionPayload } from 'utils/createActions';
import { PropertyInfo } from './types';

export const FETCH_PROPERTY = 'property/FETCH_DATA';
export const FETCH_PROPERTY_SUCCESS = 'property/FETCH_DATA_SUCCESS';
export const FETCH_PROPERTY_ERROR = 'property/FETCH_DATA_ERROR';

export interface FetchPropertyParams {}

export interface FetchPropertySuccessParams {
  property: PropertyInfo;
}

export const fetchPropertyActions = createActions<
  FetchPropertyParams,
  FetchPropertySuccessParams,
  ErrorActionPayload,
  typeof FETCH_PROPERTY,
  typeof FETCH_PROPERTY_SUCCESS,
  typeof FETCH_PROPERTY_ERROR
>(FETCH_PROPERTY, FETCH_PROPERTY_SUCCESS, FETCH_PROPERTY_ERROR);

export type PropertyActionType =
  | ReturnType<typeof fetchPropertyActions.init>
  | ReturnType<typeof fetchPropertyActions.success>
  | ReturnType<typeof fetchPropertyActions.error>;
