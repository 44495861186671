export const initials = (name?: string) => {
  if (!name) {
    return '??';
  }

  const words = name.split(/\s+/);
  return words.length === 1
    ? `${words[0].charAt(0)}${words[0].charAt(1)}`
    : `${words[0].charAt(0)}${words[1].charAt(0)}`;
};
