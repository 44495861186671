import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { colors } from '@livly/styleguide-components';

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { classes } = this.props;

    if (this.state.hasError) {
      return (
        <div className={classes.wrapper}>
          <div className={classes.title}>Oops!</div>
          <div className={classes.subtitle}>
            Looks like something unexpected happened...
          </div>
          <div className={classes.helperText}>
            We're looking into the problem, but for now try{' '}
            <a className={classes.startOver} href="/">
              starting over.
            </a>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

const styles = {
  wrapper: {
    backgroundColor: '#f6f6f6',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    flexDirection: 'column' as 'column',
  },
  title: {
    color: colors.livly(),
    fontSize: 200,
    fontWeight: 700,
    marginBottom: 120,
  },
  subtitle: {
    color: colors.felix(),
    fontSize: 36,
    marginBottom: 40,
  },
  helperText: {
    color: colors.bugs(),
    fontSize: 28,
  },
  startOver: {
    textDecoration: 'none',
    color: colors.livly(),
  },
};

interface Props extends WithStyles<typeof styles> {
  children: React.ReactElement;
}

interface State {
  hasError: boolean;
}

export default withStyles(styles)(ErrorBoundary);
