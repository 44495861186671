import {
  CheckInActionType,
  SUBMIT,
  SUBMIT_ERROR,
  SUBMIT_SUCCESS,
} from './actions';

export interface CheckInState {
  notified?: string;
}

const checkInDefaultState: CheckInState = {
  notified: undefined,
};

const checkIn = (
  state: CheckInState = checkInDefaultState,
  action: CheckInActionType
) => {
  switch (action.type) {
    case SUBMIT:
    case SUBMIT_ERROR:
      return checkInDefaultState;
    case SUBMIT_SUCCESS:
      return {
        notified: action.payload.guestName,
      };
    default:
      return state;
  }
};

export default checkIn;
