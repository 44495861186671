import axios, { Canceler } from 'axios';
import { nameSearchUrl, unitSearchUrl } from '../constants';
import { UnitResponseModel, UserResponseModel } from './types';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export const getMatchesByName = async (searchString: string) => {
  if (cancel) {
    cancel();
  }

  const response = await axios.get<UserResponseModel[]>(nameSearchUrl, {
    params: {
      searchString,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });
  return response.data;
};

export const getMatchesByUnit = async (searchString: string) => {
  if (cancel) {
    cancel();
  }

  const response = await axios.get<UnitResponseModel[]>(unitSearchUrl, {
    params: {
      searchString,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });
  return response.data;
};
