import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  makeStyles,
  Theme,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { AppState } from 'data';
import { CheckInFormValues } from 'data/checkIn/types';
import { submitCheckInThunk } from 'data/checkIn/thunks';
import { CheckInActionType } from 'data/checkIn/actions';
import { ROUTES } from '../../../constants';
import { GuestType } from 'data/guestType/types';
import { ResidentModel } from 'data/residentSearch/types';
import FormField, { FormFieldProps } from './FormField';
import GuestTypeSelector from './GuestTypeSelector';
import PhoneInput from './PhoneInput';
import InputWithModal from './InputWithModal';
import ResidentInput from './ResidentInput';
import usFlag from './united-states@3x.png';

type AppDispatch = ThunkDispatch<AppState, null, CheckInActionType>;

const CheckInFormField = (props: FormFieldProps<CheckInFormValues>) =>
  FormField(props);

const CheckInForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<CheckInFormValues>({
    initialValues: {
      resident: null,
      guestType: null,
      firstName: '',
      lastName: '',
      phone: '',
    },
    validationSchema: Yup.object().shape({
      resident: Yup.object()
        .nullable()
        .required(t('guest-form.resident.required')),
      guestType: Yup.object()
        .nullable()
        .required(t('guest-form.guest-type.required')),
      firstName: Yup.string().required(t('guest-form.first-name.required')),
      lastName: Yup.string().required(t('guest-form.last-name.required')),
      phone: Yup.string()
        .ensure()
        .required(t('guest-form.phone.required'))
        .test(
          'masked-phone',
          t('guest-form.phone.min-length'),
          async (value: string) => {
            const phoneLength = 10;
            return value.replace(/\D/g, '').length === phoneLength;
          }
        ),
    }),
    onSubmit: async (payload, { setSubmitting }) => {
      const result = await dispatch(submitCheckInThunk(payload));
      if (result) {
        history.push(ROUTES.COMPLETE);
      } else {
        enqueueSnackbar(t('guest-form.request-failed'), {
          variant: 'error',
        });
        setSubmitting(false);
      }
    },
  });

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" className={classes.inputs}>
        <Box className={classes.inputContainer}>
          <InputWithModal
            {...formik}
            className={classes.input}
            name="resident"
            label={t(`guest-form.resident`)}
            getDisplayValue={(value: ResidentModel) => value.displayName}
            content={(handleClose, rootRef) => (
              <ResidentInput
                name="resident"
                parentRef={rootRef}
                handleChange={(e) => {
                  handleClose();
                  formik.handleChange(e);
                }}
              />
            )}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <InputWithModal
            {...formik}
            className={classes.input}
            name="guestType"
            label={t(`guest-form.guest-type`)}
            getDisplayValue={(value: GuestType) => value.displayName}
            content={(handleClose) => (
              <GuestTypeSelector
                name="guestType"
                handleChange={(e) => {
                  handleClose();
                  formik.handleChange(e);
                }}
              />
            )}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <CheckInFormField
            {...formik}
            className={classes.input}
            name="firstName"
            label={t(`guest-form.first-name`)}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <CheckInFormField
            {...formik}
            className={classes.input}
            name="lastName"
            label={t(`guest-form.last-name`)}
          />
        </Box>
        <Box className={`${classes.inputContainer} ${classes.phoneInput}`}>
          <Box display="flex" mr={1} mt={3.125} alignItems="center">
            <img src={usFlag} alt="us flag" className={classes.usFlag} />
            +1
          </Box>
          <Box display="flex" flexGrow={1} flexDirection="column">
            <CheckInFormField
              {...formik}
              className={classes.input}
              label={t(`guest-form.phone`)}
              name="phone"
              customInput={PhoneInput}
            />
          </Box>
        </Box>
      </Box>

      <Box mt={6} mb={6} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => formik.submitForm()}
          disabled={!formik.isValid || formik.isSubmitting}
          className={classes.submitButton}
        >
          {formik.isSubmitting ? <CircularProgress /> : t('guest-form.submit')}
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inputs: {
    margin: '0 auto',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    width: 465,
  },
  phoneInput: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  input: {
    '& input': {
      fontSize: 20,
    },
    '& .MuiInputLabel-root': {
      fontSize: 20,
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '0.75rem',
    },
  },
  submitButton: {
    fontSize: 20,
    padding: '10px 39px',
    borderRadius: theme.spacing(1),
  },
  usFlag: {
    width: 23,
    marginRight: theme.spacing(1),
  },
}));

export default CheckInForm;
