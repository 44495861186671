import { LIVLY_TOKEN } from 'data/constants';
import auth0 from 'auth0-js';
import axios from 'axios';

import { history } from 'utils/history';
import { ROUTES } from '../../constants';

const qs: any = require('qs');
const jwt: any = require('jsonwebtoken');

export const ACCESS_TOKEN = 'access_token';
export const ID_TOKEN = 'id_token';
export const EXPIRES_AT = 'expires_at';
export const AUTHORIZATION_HEADER_KEY = 'Authorization';
export const IS_FROM_NATIVE_APP = 'from_native_app';
export const API_UNAUTHORIZED = 'api_unauthorized';
export const REALM = 'Kiosk-Guest-Connection';

export const REDIRECT_URL_AFTER_LOGING = 'login_redirect_url';

interface LoginOptions {
  email: string;
  password: string;
}

class Auth {
  tokenRenewalTimeout: any;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_REDIRECT_URL as string,
    responseType: 'token id_token',
  });

  renewalInProcess = false;

  constructor() {
    this.scheduleRenewal();
  }

  login(args: LoginOptions) {
    const { email, password } = args;
    return new Promise((res, rej) => {
      this.auth0.login(
        {
          realm: REALM,
          username: email,
          password,
        },
        (err) => {
          if (err) {
            // @ts-ignore
            const error = err.description || 'Error logging in';
            rej(error);
          }
          res();
        }
      );
    });
  }

  async logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.clear();
    axios.defaults.headers.common[AUTHORIZATION_HEADER_KEY] = undefined;
  }

  setSession(authResult: any) {
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem(ACCESS_TOKEN, authResult.accessToken);
    localStorage.setItem(ID_TOKEN, authResult.idToken);
    localStorage.setItem(EXPIRES_AT, expiresAt);
    localStorage.setItem(LIVLY_TOKEN, `Bearer ${authResult.accessToken}`);

    if (authResult.isFromNativeApp) {
      localStorage.setItem(IS_FROM_NATIVE_APP, authResult.isFromNativeApp);
    }

    // TODO: See utils/axios comments
    axios.defaults.headers.common[
      AUTHORIZATION_HEADER_KEY
    ] = localStorage.getItem(LIVLY_TOKEN);

    this.scheduleRenewal();
  }

  handleAuthentication() {
    return new Promise((res, rej) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return rej(err);
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          res(authResult);
        }
      });
    });
  }

  isAuthenticated() {
    let expiresAtValue = localStorage.getItem(EXPIRES_AT);
    const apiNotUnauthorized =
      localStorage.getItem(API_UNAUTHORIZED) !== 'true';

    if (expiresAtValue === null) {
      expiresAtValue = this.authenticateFromLocation();

      if (expiresAtValue === null) {
        return false;
      }
    }

    const expiresAt = JSON.parse(expiresAtValue),
      isNotExpired = this.renewalInProcess || new Date().getTime() < expiresAt;

    return isNotExpired && apiNotUnauthorized;
  }

  authenticateFromLocation() {
    const query = qs.parse(window.location.search.substr(1));

    if (!query.hasOwnProperty(ACCESS_TOKEN)) {
      return null;
    }

    const decodedToken = jwt.decode(query[ACCESS_TOKEN]);

    this.setSession({
      accessToken: query[ACCESS_TOKEN],
      expiresIn: decodedToken['exp'],
      idToken: null,
      isFromNativeApp: true,
    });

    return localStorage.getItem(EXPIRES_AT);
  }

  renewToken() {
    this.renewalInProcess = true;
    this.auth0.checkSession({}, (err: any, authResult: any) => {
      this.renewalInProcess = false;
      if (err) {
        history.replace(ROUTES.LOGIN);
        this.logout();
      } else {
        this.setSession(authResult);
      }
    });
  }

  scheduleRenewal() {
    const expiresAt = localStorage.getItem(EXPIRES_AT);

    if (!expiresAt) return;

    const parsedExpiresAt = JSON.parse(expiresAt);
    const delay = parsedExpiresAt - Date.now();

    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    } else {
      this.renewToken();
    }
  }

  setUnauthorized() {
    // Only set api_unauthorized if we are actually logged in
    if (Object.keys(localStorage).findIndex((key) => key === EXPIRES_AT) >= 0) {
      localStorage.setItem(API_UNAUTHORIZED, 'true');
    }
  }

  setRedirectUrlAfterLogin(path?: string): void {
    if (!path) return;
    localStorage.setItem(REDIRECT_URL_AFTER_LOGING, path);
  }
}

export default Auth;
