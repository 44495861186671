import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Theme,
  Container,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Icon, colors } from '@livly/styleguide-components';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import CheckInForm from '../components/CheckIn/CheckInForm';

const CheckIn = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Box
        pt={8.5}
        mb={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" mb={4.75} justifyContent="center">
          <Icon name="user" size={32} color={colors.sonic()} weight="solid" />
        </Box>
        <Typography variant="h3" component="h3" className={classes.title}>
          {t('guest-form.title')}
        </Typography>
      </Box>
      <CheckInForm />
      <Link to={ROUTES.ROOT}>
        <IconButton className={classes.homeButton}>
          <Icon
            name="home-alt"
            size={32}
            color={colors.felix()}
            weight="solid"
          />
        </IconButton>
      </Link>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(25),
    textAlign: 'center',
  },
  homeButton: {
    position: 'absolute',
    top: 45,
    right: 60,
  },
}));

export default CheckIn;
