import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../index';
import { ResidentType } from '../residentSearch/types';
import { CheckInActionType, submitCheckInActions } from './actions';
import { notifyResident } from './api';
import { CheckInFormValues, GuestCheckInRequest } from './types';

function transformToRequest(payload: CheckInFormValues): GuestCheckInRequest {
  return {
    userId:
      payload.resident?.type === ResidentType.Name
        ? Number(payload.resident.id)
        : undefined,
    unitNumber:
      payload.resident?.type === ResidentType.Unit
        ? payload.resident.id
        : undefined,
    guestType: payload.guestType?.displayName || '',
    firstName: payload.firstName,
    lastName: payload.lastName,
    phoneNumber: payload.phone.replace(/\D/g, ''),
  };
}

export const submitCheckInThunk = (payload: CheckInFormValues) => async (
  dispatch: ThunkDispatch<AppState, null, CheckInActionType>
) => {
  dispatch(
    submitCheckInActions.init({
      form: payload,
    })
  );
  try {
    const request = transformToRequest(payload);
    await notifyResident(request);
    dispatch(
      submitCheckInActions.success({
        guestName: payload.resident?.displayName,
      })
    );
    return true;
  } catch (error) {
    dispatch(
      submitCheckInActions.error({
        error: error.message || error,
      })
    );
    return false;
  }
};
