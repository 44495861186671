import { FormikHandlers } from 'formik';
import { FormikState } from 'formik/dist/types';
import React, {
  ReactNode,
  RefObject,
  useMemo,
  useRef,
  useState,
  Fragment,
} from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  makeStyles,
  Theme,
  Dialog,
  IconButton,
  Input,
  Slide,
  InputLabel,
  FormHelperText,
  FormControl,
  Container,
} from '@material-ui/core';
import { colors, Icon } from '@livly/styleguide-components';

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const InputWithModal = <FormModel extends {}>({
  name,
  label,
  errors,
  values,
  content,
  touched,
  handleBlur,
  id = String(name),
  className,
  getDisplayValue,
}: FormFieldProps<FormModel>) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const hasError = Boolean(errors[name] && touched[name]);
  const rootRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setOpen(false);
    handleBlur({
      target: {
        name,
      },
    });
  };

  const handleInputFocus = () => {
    setOpen(true);
  };

  const displayName = useMemo(() => {
    let value = values[name] || '';
    if (getDisplayValue && value) {
      value = getDisplayValue(value);
    }
    return value;
  }, [values, name, getDisplayValue]);

  return (
    <>
      <FormControl error={hasError} className={className} key={`${id}-${open}`}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Input
          id={id}
          name={String(name)}
          value={displayName}
          onFocus={handleInputFocus}
        />
        {hasError && <FormHelperText>{errors[name]}</FormHelperText>}
      </FormControl>
      <Dialog
        ref={rootRef}
        fullScreen
        open={open}
        scroll="body"
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Icon name="times" size={26} color={colors.felix()} weight="solid" />
        </IconButton>
        <Container maxWidth="sm">
          <Fragment>{content(handleClose, rootRef)}</Fragment>
        </Container>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    top: 45,
    right: 60,
  },
}));

export interface FormFieldProps<FormModel>
  extends FormikState<FormModel>,
    FormikHandlers {
  name: keyof FormModel;
  content: (
    handleClose: () => void,
    scrollRoot?: RefObject<Element>
  ) => ReactNode;
  label: string;
  id?: string;
  className?: string;
  getDisplayValue?: (value: any) => string;
}

export default InputWithModal;
