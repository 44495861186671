import i18n from 'i18next';
import ICU from 'i18next-icu';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'i18next-icu/locale-data/en';

i18n
  .use(
    new ICU({
      localeData: en,
    })
  )
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      useSuspense: false,
    },
  });

export const getLanguage = () => i18n.language || localStorage.i18nextLng || '';

export default i18n;
