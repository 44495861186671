import axios from 'axios';
import { LIVLY_TOKEN } from 'data/constants';
import Auth, { AUTHORIZATION_HEADER_KEY } from 'data/auth';
import { get } from 'lodash';
import { history } from './history';
import { ROUTES } from '../constants';

axios.defaults.headers.common[AUTHORIZATION_HEADER_KEY] = localStorage.getItem(
  LIVLY_TOKEN
);

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (get(error, 'response.status') === 401) {
      handleUnauthorizedError();
    }
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    return Promise.reject(error.response);
  }
);

function handleUnauthorizedError() {
  const auth = new Auth();
  auth.setUnauthorized();
  history.push(ROUTES.LOGIN);
}

export default axios;
