import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Box, Button, Input, Theme } from '@material-ui/core';
import * as Yup from 'yup';
import Auth from 'data/auth';
import FormField, { FormFieldProps } from '../components/CheckIn/FormField';

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginFormField = (props: FormFieldProps<LoginFormValues>) =>
  FormField(props);

const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useMemo(() => new Auth(), []);

  useEffect(() => {
    auth.logout();
  }, [auth]);

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    }),
    onSubmit: async (payload, { setSubmitting }) => {
      auth.login(payload).catch((err) => {
        enqueueSnackbar(err, {
          variant: 'error',
        });
        setSubmitting(false);
      });
    },
  });

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <LoginFormField
          {...formik}
          className={classes.input}
          name="email"
          label={t(`login.email`)}
        />
      </Box>
      <Box mt={2} mb={2}>
        <LoginFormField
          {...formik}
          className={classes.input}
          name="password"
          customInput={(props) => <Input type="password" {...props} />}
          label={t(`login.password`)}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => formik.submitForm()}
        disabled={!formik.isValid || formik.isSubmitting}
      >
        {t('login.login')}
      </Button>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(/assets/home_background.svg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  input: {
    minWidth: '300px',
    '& input': {
      width: '100%',
    },
  },
}));

export default Login;
