import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { AppState } from '../index';
import { searchResidentActions, SearchResidentActionType } from './actions';
import { getMatchesByName, getMatchesByUnit } from './api';
import {
  convertNameSearchToResidentModel,
  convertUnitSearchToResidentModel,
  ResidentModel,
  ResidentType,
} from './types';

const getNameMatches = async (searchString: string) => {
  const users = await getMatchesByName(searchString);
  return users.map((user) => convertNameSearchToResidentModel(user));
};

const getUnitMatches = async (searchString: string) => {
  const units = await getMatchesByUnit(searchString);
  return units.map((unit) => convertUnitSearchToResidentModel(unit));
};

export const searchResident = (
  searchString: string,
  type: ResidentType
) => async (
  dispatch: ThunkDispatch<AppState, null, SearchResidentActionType>
) => {
  dispatch(
    searchResidentActions.init({
      searchString,
      type,
    })
  );
  try {
    let getMatches: (searchString: string) => Promise<ResidentModel[]>;
    switch (type) {
      case ResidentType.Name:
        getMatches = getNameMatches;
        break;
      case ResidentType.Unit:
        getMatches = getUnitMatches;
        break;
      default:
        throw new Error('Unsupported search type');
    }

    const matches = await getMatches(searchString);
    dispatch(
      searchResidentActions.success({
        searchString,
        type,
        matches,
      })
    );

    return matches;
  } catch (error) {
    if (axios.isCancel(error)) {
      return [];
    }
    dispatch(searchResidentActions.error({ error: error.message || error }));
    return false;
  }
};
