import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Auth from 'data/auth';
import { ROUTES } from '../../constants';

function Callback({ history, auth }: Props) {
  const dispatch = useDispatch();
  const [hasAuthed, setHasAuthed] = useState(false);

  useEffect(() => {
    async function handleAuth() {
      await auth.handleAuthentication();
      setHasAuthed(true);
    }
    handleAuth();
  }, [auth]);

  useEffect(() => {
    if (hasAuthed) {
      history.push(ROUTES.ROOT);
    }
  }, [history, hasAuthed, dispatch]);

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
}

interface Props extends RouteComponentProps {
  auth: Auth;
}

// @ts-ignore
export default withRouter(Callback);
