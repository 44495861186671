import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { colors } from '@livly/styleguide-components';

// Font Families
export const fontFamilyRoboto = `"Roboto", "Helvetica", "Arial", "sans-serif"`;
export const fontFamilyGeometria = `"Geometria", "Roboto", "Helvetica", "Arial", "sans-serif"`;
export const fontFamilyNoeDisplayBlack = `'NoeDisplay-Black', 'Helvetica', 'Arial', 'sans-serif'`;
export const fontFmailyNoeDisplayBold = `'NoeDisplay-Bold', 'Helvetica', 'Arial', 'sans-serif'`;

const theme: ThemeOptions = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiTab: {
      root: {
        padding: '12px 16px',
      },
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important',
        border: '1px solid var(--tomcat)',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'var(--sonic)',
        },
        '&$active': {
          color: 'var(--sonic)',
        },
      },
      text: {
        fill: 'var(--space-ghost)',
      },
      completed: {},
      active: {},
    },
    MuiOutlinedInput: {
      root: {
        '&$focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--felix)',
            borderWidth: 1,
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
    MuiSelect: {
      root: {
        fontSize: 14,
      },
    },
    MuiCircularProgress: {
      root: {
        color: 'var(--livly)',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.tomcat(),
      },
    },
    MuiTableContainer: {
      root: {
        borderRadius: 5,
        border: '1px solid var(--tomcat)',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#EAEAEA',
      },
    },
    MuiTableCell: {
      head: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        color: '#262B2BB3',
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
  },
};

export default theme;
