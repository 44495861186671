import {
  createActions,
  createSingleAction,
  ErrorActionPayload,
} from 'utils/createActions';
import { ResidentModel, ResidentType } from './types';

export const SEARCH = 'resident/SEARCH';
export const SEARCH_SUCCESS = 'resident/SEARCH_SUCCESS';
export const SEARCH_ERROR = 'resident/SEARCH_ERROR';
export const RESET_SEARCH = 'resident/RESET_SEARCH';

export interface SearchParams {
  searchString: string;
  type: ResidentType;
}

export interface SearchSuccessParams {
  searchString: string;
  type: ResidentType;
  matches: ResidentModel[];
}

export const searchResidentActions = createActions<
  SearchParams,
  SearchSuccessParams,
  ErrorActionPayload,
  typeof SEARCH,
  typeof SEARCH_SUCCESS,
  typeof SEARCH_ERROR
>(SEARCH, SEARCH_SUCCESS, SEARCH_ERROR);

export const resetSearchAction = createSingleAction<{}, typeof RESET_SEARCH>(
  RESET_SEARCH
);

export type SearchResidentActionType =
  | ReturnType<typeof resetSearchAction>
  | ReturnType<typeof searchResidentActions.init>
  | ReturnType<typeof searchResidentActions.success>
  | ReturnType<typeof searchResidentActions.error>;
