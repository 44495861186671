import { colors } from '@livly/styleguide-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Box,
  Button,
  Theme,
  Typography,
  CircularProgress,
  Avatar,
} from '@material-ui/core';
import { AppState } from 'data';
import { Link } from 'react-router-dom';
import { fetchProperty } from 'data/property/thunks';
import { ROUTES } from '../../constants';
import { FETCH_STATUSES } from 'data/base/types';
import { initials } from 'utils/text';

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const property = useSelector((state: AppState) => state.property.data);

  useEffect(() => {
    if (!property) {
      dispatch(fetchProperty());
    }
  }, [dispatch, property]);
  const loading = useSelector(
    (state: AppState) => state.property.status === FETCH_STATUSES.LOADING
  );

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {!property && loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {property && (
        <>
          <Box mb={7} mt={-6}>
            <Avatar
              className={classes.logo}
              alt={property?.displayName}
              src={property?.logoUrl}
            >
              {initials(property?.displayName)}
            </Avatar>
          </Box>
          <Box mb={11}>
            <Typography variant="h2" component="h2" className={classes.title}>
              {t('home.welcome', { propertyName: property?.displayName })}
            </Typography>
          </Box>
          <Link to={ROUTES.CHECK_IN}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
            >
              {t('home.check-in')}
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(/assets/home_background.svg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  logo: {
    maxHeight: 100,
    height: 100,
    minWidth: 100,
    backgroundColor: colors.felix(),
    color: colors.spaceGhost(),
    fontSize: theme.typography.pxToRem(50),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(30),
  },
  button: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    padding: 15,
    minWidth: '300px',
    borderRadius: theme.spacing(1),
  },
}));

export default Home;
