import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GuestType } from 'data/guestType/types';
import { guestTypesSelector } from 'data/guestType/selectors';

const GuestTypeSelector = ({ name, handleChange }: FormFieldProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selected, setSelected] = useState<GuestType | null>(null);
  const guestTypes = useSelector(guestTypesSelector);

  const handleSelect = () => {
    handleChange({
      target: {
        name,
        value: selected,
      },
    });
  };

  return (
    <Box>
      <Box
        pt={8.5}
        mb={9.5}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography variant="h3" component="h3" className={classes.title}>
          {t('guest-form.guest.select')}
        </Typography>
      </Box>
      <Grid container spacing={5}>
        {guestTypes.map((guestType) => (
          <Grid item xs={6} key={guestType.id}>
            <Button
              fullWidth
              variant="outlined"
              className={classes.option}
              onClick={() => setSelected(guestType)}
              color={selected?.id === guestType.id ? 'primary' : 'default'}
              size="large"
            >
              {guestType.displayName}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Box mt={17.5} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSelect}
          disabled={!selected}
          className={classes.submit}
        >
          {t('common.select')}
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(25),
    textAlign: 'center',
  },
  option: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightMedium,
    height: 72,
    borderWidth: 2,
  },
  submit: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    height: 56,
    width: 248,
  },
}));

export interface FormFieldProps {
  name: string;
  handleChange: (event: any) => void;
}

export default GuestTypeSelector;
