import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../index';
import { fetchPropertyActions, PropertyActionType } from './actions';
import { getPropertyInfo } from './api';

export const fetchProperty = () => async (
  dispatch: ThunkDispatch<AppState, null, PropertyActionType>
) => {
  dispatch(fetchPropertyActions.init({}));
  try {
    const property = await getPropertyInfo();
    dispatch(
      fetchPropertyActions.success({
        property,
      })
    );
    return true;
  } catch (error) {
    dispatch(
      fetchPropertyActions.error({
        error: error.message || error,
      })
    );
    return false;
  }
};
