import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
} from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import checkIn from './checkIn/reducer';
import property from './property/reducer';
import guestTypes from './guestType/reducer';
import residentSearch from './residentSearch/reducer';

const rootReducer = combineReducers({
  checkIn,
  property,
  guestTypes,
  residentSearch,
});

export default rootReducer;

const middlewares: Middleware[] = [ReduxThunk];

export const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middlewares))
);

export type AppState = ReturnType<typeof rootReducer>;
