export interface ErrorActionPayload {
  error: any;
}

export function createSingleAction<PayloadType, ActionType>(
  actionType: ActionType
) {
  return (payload: PayloadType) => ({
    type: actionType,
    payload,
  });
}

export function createActions<
  RequestType,
  SuccessType,
  ErrorType extends ErrorActionPayload,
  RequestActionType,
  SuccessActionType,
  ErrorActionType
>(
  requestType: RequestActionType,
  successType: SuccessActionType,
  errorType: ErrorActionType
) {
  return {
    init: createSingleAction<RequestType, RequestActionType>(requestType),
    success: createSingleAction<SuccessType, SuccessActionType>(successType),
    error: createSingleAction<ErrorType, ErrorActionType>(errorType),
  };
}
