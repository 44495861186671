import React, {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { colors, Icon } from '@livly/styleguide-components';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { AppState } from 'data';
import {
  resetSearchAction,
  SearchResidentActionType,
} from 'data/residentSearch/actions';
import { searchResident } from 'data/residentSearch/thunks';
import { ResidentModel, ResidentType } from 'data/residentSearch/types';
import {
  residentSearchSelector,
  residentSearchLoadingSelector,
} from 'data/residentSearch/selectors';
import { groupOptions } from './utils';

type AppDispatch = ThunkDispatch<AppState, null, SearchResidentActionType>;

const ResidentInput = ({ name, handleChange, parentRef }: FormFieldProps) => {
  const minSearchLength = 2;
  const debounceTimeout = 250;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [type, setType] = useState(ResidentType.Name);
  const [searchString, setSearchString] = useState('');
  const [debounced, setDebounced] = useState(false);
  const matches = useSelector(residentSearchSelector);
  const loading = useSelector(residentSearchLoadingSelector);
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const tabs = {
    [ResidentType.Name]: {
      type: ResidentType.Name,
      title: t('resident-select.name'),
      placeholder: t('resident-select.name.placeholder'),
    },
    [ResidentType.Unit]: {
      type: ResidentType.Unit,
      title: t('resident-select.unit'),
      placeholder: t('resident-select.unit.placeholder'),
    },
  };
  useEffect(() => {
    setSearchString('');
    dispatch(resetSearchAction({}));
  }, [type, dispatch]);

  const loadMatches = useMemo(
    () =>
      debounce(async (searchString: string) => {
        const result = await dispatch(searchResident(searchString, type));
        setDebounced(false);
        if (!result) {
          enqueueSnackbar(t('resident-select.error'), {
            variant: 'error',
          });
        }
      }, debounceTimeout),
    [dispatch, type, enqueueSnackbar, t]
  );
  const onChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setSearchString(event.target.value);
    if (newValue && newValue.length >= minSearchLength) {
      setDebounced(true);
      loadMatches(newValue);
    } else {
      dispatch(resetSearchAction({}));
    }
  };

  const handleSelect = (newValue: ResidentModel) => {
    handleChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  const groupBy =
    type === ResidentType.Name
      ? (option: ResidentModel) => option.groupBy || ''
      : undefined;
  const optionGroups = groupOptions(matches, groupBy);

  const handleSmallScreenHeight = useCallback(() => {
    const scrollRootClass = 'MuiDialog-paperScrollBody';
    const resizeTimeout = 100;
    const topOffset = 8;
    setTimeout(() => {
      const scrollRoot = parentRef?.current
        ?.getElementsByClassName(scrollRootClass)
        .item(0);
      if (scrollRoot) {
        const top = (autocompleteRef?.current?.offsetTop || 0) - topOffset;
        scrollRoot.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }, resizeTimeout);
  }, [parentRef, autocompleteRef]);

  useEffect(() => {
    window.addEventListener('resize', handleSmallScreenHeight);
    return () => {
      window.removeEventListener('resize', handleSmallScreenHeight);
    };
  }, [handleSmallScreenHeight]);

  const validInput = searchString.length >= minSearchLength;

  return (
    <Box>
      <Box
        pt={8.5}
        mb={4}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography variant="h3" component="h3" className={classes.title}>
          {t('guest-form.resident.select')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        {Object.values(tabs).map((searchType) => (
          <Box
            key={searchType.type}
            className={`${classes.tab} ${
              type === searchType.type && classes.tabActive
            }`}
            onClick={() => setType(searchType.type)}
          >
            {searchType.title}
          </Box>
        ))}
      </Box>
      <Box mt={4} mb={30}>
        <TextField
          ref={autocompleteRef}
          placeholder={tabs[type].placeholder}
          variant="outlined"
          fullWidth
          value={searchString}
          onFocus={handleSmallScreenHeight}
          onChange={onChangeInput}
          className={classes.input}
          InputProps={{
            startAdornment: (
              <Box mr={1} ml={1}>
                <Icon
                  name="search"
                  size={16}
                  color={colors.bugs()}
                  weight="solid"
                />
              </Box>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
              </>
            ),
          }}
        />
        {!loading && validInput && (
          <>
            {optionGroups.length > 0 && (
              <List>
                {optionGroups.map((group) => (
                  <div key={group.index}>
                    {group.group && (
                      <ListSubheader className={classes.groupLabel}>
                        {group.group}
                      </ListSubheader>
                    )}
                    {group.options.map((item) => (
                      <ListItem
                        key={item.id}
                        onClick={() => handleSelect(item)}
                        className={classes.option}
                      >
                        <ListItemText primary={item.displayName} />
                      </ListItem>
                    ))}
                  </div>
                ))}
              </List>
            )}
            {!debounced && optionGroups.length === 0 && (
              <List>
                <ListItem>
                  <ListItemText primary={t('resident-select.no-options')} />
                </ListItem>
              </List>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(25),
    textAlign: 'center',
  },
  tab: {
    cursor: 'pointer',
    background: colors.spaceGhost(),
    transition: 'background .3s ease',
    fontSize: theme.typography.pxToRem(16),
    height: 32,
    borderRadius: 16,
    padding: theme.spacing(0, 3),
    display: 'inline-flex',
    alignItems: 'center',
    '&+&': {
      marginLeft: theme.spacing(4),
    },
  },
  tabActive: {
    background: colors.magilla(),
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    '& input': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  option: {
    borderBottom: `1px solid ${colors.bugs(0.5)}`,
  },
  groupLabel: {
    background: colors.spaceGhost(),
    color: colors.sonic(),
  },
}));

export interface FormFieldProps {
  name: string;
  handleChange: (event: any) => void;
  parentRef?: RefObject<Element>;
}

export default ResidentInput;
