import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { createLivlyTheme } from '@livly/styleguide-components';
import { SnackbarProvider } from 'notistack';
import { history } from 'utils/history';
import KioskTheme from 'ui/styles/theme';
import { ROUTES } from './constants';
import ErrorBoundary from 'ui/components/ErrorBoundary';
import AnimatedSwitch from 'ui/components/AnimatedSwitch/AnimatedSwitch';
import AnimatedPage from 'ui/components/AnimatedSwitch/AnimatedPage';
import Login from 'ui/screens/Login';
import Auth from './data/auth';
import Callback from './ui/components/Callback';
import PrivateRoute from './ui/components/PrivateRoute';
import Landing from './ui/screens/Landing';

function App({ auth }: Props) {
  return (
    <div className="App">
      <ErrorBoundary>
        <SnackbarProvider maxSnack={3}>
          <MuiThemeProvider theme={createLivlyTheme(KioskTheme)}>
            <Router history={history}>
              <AnimatedSwitch>
                <Route
                  exact
                  path={ROUTES.LOGIN}
                  render={() => (
                    <AnimatedPage>
                      <Login />
                    </AnimatedPage>
                  )}
                />
                <Route
                  exact
                  path={ROUTES.CALLBACK}
                  render={() => (
                    <AnimatedPage>
                      <Callback auth={auth} />
                    </AnimatedPage>
                  )}
                />
                <PrivateRoute
                  auth={auth}
                  path={ROUTES.ROOT}
                  component={Landing}
                />
                <Route render={() => <Redirect to={ROUTES.ROOT} />} />
              </AnimatedSwitch>
            </Router>
          </MuiThemeProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </div>
  );
}

interface Props {
  auth: Auth;
}

export default App;
