import React from 'react';
import { makeStyles, Theme, Box } from '@material-ui/core';
import { colors } from '@livly/styleguide-components';

const AnimatedPage = ({ children }: Props) => {
  const classes = useStyles();

  return <Box className={classes.container}>{children}</Box>;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: colors.spaceGhost(),
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  children: React.ReactNode;
}

export default AnimatedPage;
