import { FETCH_STATUSES, RemoteData } from 'data/base/types';
import {
  RESET_SEARCH,
  SEARCH,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
  SearchResidentActionType,
} from './actions';
import { ResidentModel } from './types';

const defaultState = {
  data: [],
  status: FETCH_STATUSES.NEVER,
};

const residentSearch = (
  state: RemoteData<ResidentModel[]> = defaultState,
  action: SearchResidentActionType
) => {
  switch (action.type) {
    case RESET_SEARCH:
      return defaultState;
    case SEARCH:
      return {
        ...defaultState,
        status: FETCH_STATUSES.LOADING,
      };
    case SEARCH_SUCCESS: {
      const { matches } = action.payload;
      return {
        data: matches,
        status: FETCH_STATUSES.OK,
      };
    }
    case SEARCH_ERROR:
      return {
        ...defaultState,
        status: FETCH_STATUSES.ERROR,
      };
    default:
      return state;
  }
};

export default residentSearch;
