import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { makeStyles, Theme } from '@material-ui/core';
import './styles.css';

const AnimatedSwitch = ({ children }: Props) => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <TransitionGroup className={classes.container}>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '100%',
  },
  fade: {
    transition: 'opacity 250ms ease-in',
    '&-enter': {
      opacity: 0,
      zIndex: 1,
    },
    '&-enter-active': {
      opacity: 1,
    },
  },
}));

interface Props {
  children: React.ReactNode;
}

export default AnimatedSwitch;
