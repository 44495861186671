export enum ResidentType {
  Name,
  Unit,
}

export interface ResidentModel {
  id: string;
  displayName: string;
  groupBy?: string;
  type: ResidentType;
}

export interface UserResponseModel {
  firstName: string;
  lastName: string;
  userId: number;
}

export const convertNameSearchToResidentModel = (
  user: UserResponseModel
): ResidentModel => ({
  id: String(user.userId),
  displayName: `${user.firstName} ${user.lastName}`,
  type: ResidentType.Name,
  groupBy: user.firstName[0],
});

export interface UnitResponseModel {
  unitNumber: string;
}

export const convertUnitSearchToResidentModel = (
  unit: UnitResponseModel
): ResidentModel => ({
  id: unit.unitNumber,
  displayName: `Unit ${unit.unitNumber}`,
  type: ResidentType.Unit,
});
