import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles, Box, Button, Theme, Typography } from '@material-ui/core';
import { colors, Icon } from '@livly/styleguide-components';
import { ROUTES } from '../../constants';
import { notifiedGuestSelector } from 'data/checkIn/selectors';

const Complete = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const resident =
    useSelector(notifiedGuestSelector) || t('complete.resident-default');

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={5.5}>
        <Icon name="check" size={38} color={colors.gumby()} weight="solid" />
      </Box>
      <Box mb={3}>
        <Typography variant="h2" component="h2" className={classes.title}>
          {t('complete.title')}
        </Typography>
      </Box>
      <Box mb={6.25} justifyContent="center">
        <Typography variant="h3" component="h2" className={classes.text}>
          <span className={classes.textPrimary}>{resident}</span>{' '}
          {t('complete.notified')}
          <br />
          {t('complete.wait')}
        </Typography>
      </Box>
      <Link to={ROUTES.ROOT}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
        >
          {t('common.done')}
        </Button>
      </Link>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(/assets/complete_background.svg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(30),
  },
  text: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textPrimary: {
    color: colors.livly(),
  },
  button: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    padding: 15,
    minWidth: '300px',
    borderRadius: theme.spacing(1),
  },
}));

export default Complete;
