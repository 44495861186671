import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';
import AnimatedPage from 'ui/components/AnimatedSwitch/AnimatedPage';
import CheckIn from './CheckIn';
import Complete from './Complete';
import Home from './Home';

import useIdle from '../hooks/useIdle';

const Landing = () => {
  const history = useHistory();
  const isIdle = useIdle({ timeToIdle: 1000 * 60, ignoredEventsWhenIdle: [] });

  React.useEffect(() => {
    if (isIdle) {
      history.push(ROUTES.ROOT);
    }
  }, [isIdle, history]);

  return (
    <>
      <Route
        exact
        path={ROUTES.CHECK_IN}
        component={() => (
          <AnimatedPage>
            <CheckIn />
          </AnimatedPage>
        )}
      />
      <Route
        exact
        path={ROUTES.COMPLETE}
        component={() => (
          <AnimatedPage>
            <Complete />
          </AnimatedPage>
        )}
      />
      <Route
        exact
        path={ROUTES.ROOT}
        component={() => (
          <AnimatedPage>
            <Home />
          </AnimatedPage>
        )}
      />
    </>
  );
};

export default Landing;
