import React, { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  InputProps,
} from '@material-ui/core';
import { FormikHandlers } from 'formik';
import { FormikState } from 'formik/dist/types';

const FormField = <FormModel extends {}>({
  name,
  label,
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  customInput,
  id = String(name),
  className,
}: FormFieldProps<FormModel>) => {
  const hasError = Boolean(errors[name] && touched[name]);
  return (
    <FormControl error={hasError} className={className} key={id}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      {customInput ? (
        customInput({
          id,
          name: String(name),
          onChange: handleChange,
          onBlur: handleBlur,
          value: values[name],
        })
      ) : (
        <Input
          id={id}
          name={String(name)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
        />
      )}
      {hasError && <FormHelperText>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};

export default FormField;

export interface FormFieldProps<FormModel>
  extends FormikState<FormModel>,
    FormikHandlers {
  name: keyof FormModel;
  label: string;
  customInput?: (prop: InputProps) => ReactNode;
  id?: string;
  className?: string;
}
