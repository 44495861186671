import { FETCH_STATUSES, RemoteData } from 'data/base/types';
import {
  FETCH_PROPERTY,
  FETCH_PROPERTY_ERROR,
  FETCH_PROPERTY_SUCCESS,
  PropertyActionType,
} from './actions';
import { PropertyInfo } from './types';

const defaultState = {
  data: null,
  status: FETCH_STATUSES.NEVER,
};

const property = (
  state: RemoteData<PropertyInfo> = defaultState,
  action: PropertyActionType
) => {
  switch (action.type) {
    case FETCH_PROPERTY:
      return {
        ...state,
        status: FETCH_STATUSES.LOADING,
      };
    case FETCH_PROPERTY_SUCCESS: {
      const data = action.payload.property;
      return {
        data,
        status: FETCH_STATUSES.OK,
      };
    }
    case FETCH_PROPERTY_ERROR:
      return {
        ...state,
        status: FETCH_STATUSES.ERROR,
      };
    default:
      return state;
  }
};

export default property;
