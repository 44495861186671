import React from 'react';
import InputMask from 'react-input-mask';
import { Input, InputProps } from '@material-ui/core';

const PhoneInput = (props: InputProps) => {
  const phoneMask = '(999) 999 - 9999';
  return (
    <InputMask
      mask={phoneMask}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      value={String(props.value)}
    >
      {() => <Input id={props.id} name={props.name} />}
    </InputMask>
  );
};

export default PhoneInput;
