export interface BaseLivlyApiResponse<DataType> {
  Data: DataType;
  Errors: object;
  Message: string;
  Meta: {
    pagination?: PaginationType;
  };
}

export const FETCH_STATUSES = {
  NEVER: 'never',
  OK: 'ok',
  ERROR: 'error',
  LOADING: 'loading',
  UPDATING: 'updating',
};

export type FetchStatus =
  | typeof FETCH_STATUSES.NEVER
  | typeof FETCH_STATUSES.OK
  | typeof FETCH_STATUSES.ERROR
  | typeof FETCH_STATUSES.LOADING
  | typeof FETCH_STATUSES.UPDATING;

export interface RemoteData<DataType, MetaType = any> {
  data: DataType | null;
  expires?: Date;
  status: FetchStatus;
  error?: string;
  meta?: MetaType;
}

export interface DataById<DataType> {
  [key: number]: DataType;
}

export interface PaginationType {
  count: number;
  has_next_page: boolean;
  has_prev_page: boolean;
  limit: number;
  page: number;
  page_count: number;
}
