import { createActions, ErrorActionPayload } from 'utils/createActions';
import { CheckInFormValues } from './types';

export const SUBMIT = 'check_in/SUBMIT';
export const SUBMIT_SUCCESS = 'check_in/SUBMIT_SUCCESS';
export const SUBMIT_ERROR = 'check_in/SUBMIT_ERROR';

export interface SubmitFormParams {
  form: CheckInFormValues;
}

export interface SubmitFormSuccessParams {
  guestName?: string;
}

export const submitCheckInActions = createActions<
  SubmitFormParams,
  SubmitFormSuccessParams,
  ErrorActionPayload,
  typeof SUBMIT,
  typeof SUBMIT_SUCCESS,
  typeof SUBMIT_ERROR
>(SUBMIT, SUBMIT_SUCCESS, SUBMIT_ERROR);

export type CheckInActionType =
  | ReturnType<typeof submitCheckInActions.init>
  | ReturnType<typeof submitCheckInActions.success>
  | ReturnType<typeof submitCheckInActions.error>;
