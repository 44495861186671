import { sortBy } from 'lodash';
import { ResidentModel } from 'data/residentSearch/types';

export interface OptionGroup {
  index: number;
  group: string | null;
  options: ResidentModel[];
}

export const groupOptions = (
  options: ResidentModel[],
  groupByFunction?: (option: ResidentModel) => string
) => {
  if (options.length === 0) {
    return [];
  }
  const sortedOptions = sortBy(
    options,
    (option) => option.groupBy || option.displayName
  );
  if (groupByFunction) {
    const defaultGroups: OptionGroup[] = [];
    return sortedOptions.reduce((acc, option, index) => {
      const group = groupByFunction(option);

      if (acc.length > 0 && acc[acc.length - 1].group === group) {
        acc[acc.length - 1].options.push(option);
      } else {
        acc.push({
          index,
          group,
          options: [option],
        });
      }

      return acc;
    }, defaultGroups);
  }
  return [
    {
      index: 1,
      group: null,
      options: sortedOptions,
    },
  ];
};
