import { FETCH_STATUSES, RemoteData } from 'data/base/types';
import { GuestType } from './types';

// TODO: Fetch property from API
const defaultState = {
  data: [
    {
      id: 1,
      displayName: 'Personal Guest',
    },
    {
      id: 2,
      displayName: 'Dog Walker',
    },
    {
      id: 3,
      displayName: 'Cleaner',
    },
    {
      id: 4,
      displayName: 'Delivery',
    },
    {
      id: 5,
      displayName: 'Other Business',
    },
  ],
  status: FETCH_STATUSES.OK,
};

const guestType = (state: RemoteData<GuestType[]> = defaultState) => {
  return state;
};

export default guestType;
